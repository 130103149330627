<template>
  <div class="buttons flex gap-9">
     <!-- class="i-ml-35" -->
    <div>
      <button :class="{'cursor-not-allowed': !activeDataId || activeDataId == null || activeDataId === undefined, 'cursor-pointer': activeDataId}"
              :title="!activeDataId ? 'Please select a project' : 'Open style save dialog'"
              @click="toggleSaveStyleModalMode(true)"
              class="focus:outline-none text-primary-one fs-14 font-poppins fw-600 bg-white i-border-1 border-primary-four w-full rounded flex items-center justify-center i-pt-10 i-pb-10 i-pl-35 i-pr-35">
        Save Style
      </button>
    </div>
     <!-- class="i-ml-35" -->
    <div>
      <button :class="{'cursor-not-allowed': !activeDataId || activeDataId == null || activeDataId === undefined, 'cursor-pointer': activeDataId}"
              :title="!activeDataId ? 'Please select a project' : 'Open style load dialog'"
              @click="toggleLoadStyleModalMode(true)"
              class="focus:outline-none text-primary-one fs-14 font-poppins fw-600 bg-white i-border-1 border-primary-four w-full rounded flex items-center justify-center i-pt-10 i-pb-10 i-pl-35 i-pr-35">
          Load Style
      </button>
    </div>
     <!-- class="i-ml-35" -->
    <div>
      <router-link :to="{ name: 'definition', params: { id: activeDataId } }"
                   :event="activeDataId ? 'click' : ''"
                   :title="!activeDataId ? 'Please select a project' : 'Go to Definitions'"
                   :class="{'cursor-not-allowed': !activeDataId || activeDataId == null || activeDataId === undefined}"
                   class="focus:outline-none text-primary-one fs-14 font-poppins fw-600 bg-white i-border-1 border-primary-four w-full rounded flex items-center justify-center i-pt-10 i-pb-10 i-pl-35 i-pr-35">
        Definitions
      </router-link>
    </div>
    <save-style :dModal="styleModal.save" :modules="getData" :activeDataId="activeDataId"
                @toggleModalMode="toggleSaveStyleModalMode"/>
    <load-style :dModal="styleModal.load" :modules="getData" :activeDataId="activeDataId"
                @toggleModalMode="toggleLoadStyleModalMode"
                @loadStyle="loadStyle"/>
  </div>
</template>

<script>
import SaveStyle from "./SaveStyle";
import LoadStyle from "./LoadStyle";
import {mapGetters} from "vuex";
export default {
  name: "StyleButtons",
  components: {LoadStyle, SaveStyle},
  props: ['activeDataId'],
  data() {
    return {
      styleModal: {
        save: false,
        load: false
      }
    }
  },
  computed: {
    ...mapGetters({
      projects: "workspace/workspaceSettingsInfo"
    }),
    getData() {
      const self = this;
      let item = self.projects.find(item => parseInt(item.id) === parseInt(self.activeDataId));
      if (item) {
        item.modules.forEach(mData => {
          if(mData.collection_settings && mData.collection_settings.collections) {
            // do nothing
          } else {
            mData.collection_settings = JSON.parse(mData.collection_settings);
          }
        });
        return item.modules;
      }
      return [];
    },
  },
  methods: {
    toggleSaveStyleModalMode(value) {
      if(this.activeDataId) {
        this.styleModal.save = value;
      }
    },
    toggleLoadStyleModalMode(value) {
      if(this.activeDataId) {
        this.styleModal.load = value;
      }
    },
    loadStyle(data) {
      const self = this;
      let styles;
      if(Array.isArray(data)){
        styles = data;
      }else{
        styles = JSON.parse(data);
      }
      self.getData.forEach((item, index) => {
        item.collection_settings = JSON.stringify(styles[index]);
      });
    },
  }
}
</script>

<style scoped>

</style>
