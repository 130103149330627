<template>
  <div class="_main_wrapper relative flex">
    <div class="i-left-panel shadow-one">
      <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo">
      <div class="ws-boxes flex mt-12">
        <div class="flex workspace-scroll" v-click-outside="hideContextMenu">
          <div v-for="(workspace, wsIndex) in workspaces"
               :key="wsIndex"
               class="ws-box bg-primary-four font-poppins fs-16 text-primary-two p-2 uppercase fw-bold cursor-pointer rounded-sm"
               :class="{'ml-5': wsIndex > 0, 'i-border-1 shadow-two border-primary-one': parseInt(selectedWs) === parseInt(workspace.id)}"
               @click="toggleWorkspace(workspace.id, wsIndex)"
               @contextmenu="rightClickHandler($event, workspace)">
            {{ workspace.name.substring(0, 2) }}
          </div>
        </div>
        <div class="ws-box bg-primary-one cursor-pointer rounded-sm flex items-center justify-content-center p-3"
             :class="{'ml-5': workspaces.length > 0}" @click="showWorkspaceCreateModal()">
          <img src="/images/icons/workspace/plus_icon.svg" alt="icon">
        </div>
      </div>

      <div class="grid gap-3 ws-info-box i-mt-34">
        <h2 class="font-poppins fs-14 fw-600 text-primary-one">Active: {{ activeWorkspaceObj ? activeWorkspaceObj.name : '' }}</h2>
        <router-link class="mt-2" :to="{ name: 'workspace_settings', params: { id: activeWorkspaceObj ? activeWorkspaceObj.id : '' }, query: { option: 'style' }}">
          <h2 class="font-poppins fs-14 fw-600 text-primary-one opacity-100">Style Control</h2>
        </router-link>
        <router-link class="mt-2" :to="{ name: 'definition', params: { id: activeData } }"
                     :event="activeData ? 'click' : ''"
                     :title="!activeData ? 'Please select a project' : 'Go to Definitions'">
          <h2 class="font-poppins fs-14 fw-600 text-primary-one opacity-50">Definitions</h2>
        </router-link>
        <router-link class="mt-2" :to="{ name: 'workspace.members'}">
          <h2 class="font-poppins fs-14 fw-600 text-primary-one opacity-50">Role & Members</h2>
        </router-link>
      </div>
      <button class="ws-folder-button focus:outline-none hover:bg-secondary-five text-primary-one fs-14 font-poppins fw-600 bg-primary-three shadow-one w-full mt-5 rounded flex items-center justify-center" @click="goBack()">
        Go Back
      </button>

      <div class="ws-folder-box i-border-b-1 border-secondary-two overflow-y-auto">
        <div>
          <div class="flex justify-between cursor-pointer text-primary-one opacity-60" :class="{'opacity-100': categories[0].active}" @click="toggleCategory(0)">
            <h2 class="fs-14 font-poppins fw-600">Projects</h2>
            <h2 class="fs-14 font-poppins fw-600">{{ getCategoryInfo(1).length }}</h2>
          </div>
          <div class="ml-5" v-if="categories[0].active">
            <h2 v-for="(project, index) in getCategoryInfo(1)" :key="index" class="fs-14 fw-600 font-poppins text-primary-one opacity-60 mt-8 cursor-pointer"
                @click="setActiveItem(project)" :class="{'opacity-100': parseInt(project.id) === parseInt(activeData)}">
              {{ project.name }}
            </h2>
          </div>
        </div>
<!--        <div class="mt-8">-->
<!--          <div class="flex justify-between cursor-pointer text-primary-one opacity-60" :class="{'opacity-100': categories[1].active}" @click="toggleCategory(1)">-->
<!--            <h2 class="fs-14 font-poppins fw-600">Blueprints</h2>-->
<!--            <h2 class="fs-14 font-poppins fw-600">{{ getCategoryInfo(2).length }}</h2>-->
<!--          </div>-->
<!--          <div class="ml-5" v-if="categories[1].active">-->
<!--            <h2 v-for="(blueprint, index) in getCategoryInfo(2)" :key="index" class="fs-14 fw-600 font-poppins text-primary-one opacity-60 mt-8 cursor-pointer"-->
<!--                @click="setActiveItem(blueprint.id)" :class="{'opacity-100': parseInt(blueprint.id) === parseInt(activeData)}">-->
<!--              {{ blueprint.name }}-->
<!--            </h2>-->
<!--          </div>-->
<!--        </div>-->
      </div>

      <div class="ws-share-box">
        <h2 class="fs-14 font-poppins text-secondary-one">Publish Project</h2>
        <h2 class="fs-14 font-poppins text-secondary-one mt-5">Share on Instrat Community</h2>
        <h2 class="fs-14 font-poppins text-secondary-one mt-5">Invite collaborators</h2>
        <div class="social-links mt-6 flex">
          <img src="/images/icons/workspace/facebook.svg" alt="facebook" class="cursor-pointer ws-social-icon">
          <img src="/images/icons/workspace/google.svg" alt="google" class="i-ml-25 cursor-pointer ws-social-icon">
          <img src="/images/icons/workspace/linked_in.svg" alt="linked_in" class="i-ml-25 cursor-pointer ws-social-icon">
          <img src="/images/icons/workspace/youtube.svg" alt="youtube" class="i-ml-25 cursor-pointer ws-social-icon">
        </div>
      </div>
    </div>
    <div class="i-right-panel">
      <div class="ws-dashboard-heading-box flex flex-col i-border-b-1 border-secondary-two none-border ws-settings-heading">
        <workspace-menu :hide-project="true" />
        <div class="mt-4 flex items-center justify-between ws-select-dropdown ws-settings-margin">
          <div class="flex flex-wrap gap-9 buttons_wrapper">
            <div class="flex">
              <div class="relative i-border-1 border-secondary-two rounded ws-search-width">
                <input
                    type="text"
                    class="focus:outline-none py-2 font-poppins ws-search-box bg-transparent text-secondary-one placeholder-secondary-two"
                    :placeholder="searchField.placeholder"
                    v-model="searchField.text"
                    @click="searchField.placeholder = ''"
                    v-click-outside="outsideSearchInput"
                />

                <div class="mr-2 flex items-center justify-between absolute inset-y-0 right-0 pointer-events-none pr-1">
                  <img
                      class="w-3 h-3"
                      src="/images/icons/search_icon.svg"
                  />
                </div>
              </div>
            </div>
            <style-buttons :activeDataId="activeData" v-if="settingsOption === 'style' && activeData && canEdit(project.cans)" />
            <role-buttons :activeDataId="activeData" v-if="settingsOption === 'role'" />
          </div>
<!--          <div class="flex relative">-->
<!--            <img src="/images/icons/message_icon.svg" alt="icon" class="cursor-pointer">-->
<!--            <img src="/images/icons/notification_icon.svg" alt="icon" class="cursor-pointer i-ml-36" @click="notification = !notification">-->
<!--            <div class="absolute dotted-notification bg-primary-five" v-if="unreadNotification"></div>-->
<!--            <popup v-if="notification" />-->
<!--          </div>-->
        </div>
      </div>
      <div class="ws-settings overflow-y-auto ws-scrollbar pr-8">
        <project-role-table :dRoleModal="dRoleModal" :activeDataId="activeData" v-if="settingsOption === 'role' && activeData" @toggleModalMode="toggleModalMode" />
        <project-style-table
            :styleModal="styleModal"
            :activeDataId="activeData"
            :project="activeProject"
            :searchField="searchField"
            v-if="settingsOption === 'style' && activeData"
            @toggleSaveStyleModalMode="toggleSaveStyleModalMode"
            @toggleLoadStyleModalMode="toggleLoadStyleModalMode" />
      </div>
    </div>

    <div class="absolute bg-primary-three shadow-two fs-14 text-primary-one font-poppins py-3 px-3 rounded"
         style="width: 80px; height: 80px"
         v-if="showContextMenu && canEdit(contextWorkspace.cans)"
         :style="{'left': contextPos.left + 'px', 'top': contextPos.top + 'px'}">
      <ul>
        <li class="fw-600 cursor-pointer" @click="editWorkspace()" :class="{'pointer-events-none': !canEdit(contextWorkspace.cans)}">Edit</li>
        <li class="fw-600 cursor-pointer mt-3" @click="deleteWorkspace()" :class="{'pointer-events-none': !canDelete(contextWorkspace.cans)}">Delete</li>
      </ul>
    </div>
    <edit-workspace :dModal="eModal"
                    :ref="'workspace_edit'"
                    @toggleEditModal="toggleEditModal" />
  </div>
</template>

<script setup>
import usePermission from '@/composable/usePermission.js'
const { canDelete, canCreate, canEdit } = usePermission()
</script>
<script>
  import {mapGetters} from 'vuex';
  import ProjectRoleTable from "../../../components/workspace/project/ProjectRoleTable";
  import ProjectStyleTable from "../../../components/workspace/project/ProjectStyleTable";
  import WorkspaceMenu from "../../../components/InstMenu/WorkspaceMenu";
  import StyleButtons from "../../../components/workspace/project/StyleButtons";
  import RoleButtons from "../../../components/workspace/project/RoleButtons";
  // import Popup from "../../../components/notification/Popup";
  import EditWorkspace from "../../../components/workspace/WorkspaceEditModal";
  import store from "@/store";

  export default {
  name: "settings",
  components: {EditWorkspace, RoleButtons, StyleButtons, WorkspaceMenu, ProjectStyleTable, ProjectRoleTable},
  created() {
    const self = this;
    document.title = "Workspace Settings";
    if(self.workspaces.length <= 0) {
      self.$store.dispatch("workspace/fetchWorkspaces", 1).then(response => {
        if(response && response.data) {
          self.selectedWs = self.workspaces[0].id;
          self.categories[0].active = true;
          if(response.data.data.length > 0 || !this.$route.query.id) {
            this.activeData = response.data.data[0].id;
            this.activeProject = response.data.data[0];
          }
        }
      });
    } else {
      self.selectedWs = self.$route.params.id;
    }
  },
  data() {
    return {
      eModal: false,
      contextPos: {
        top: 0,
        left: 0
      },
      showContextMenu: false,
      contextWorkspace: null,
      notification: false,
      searchField: {
        placeholder: 'Search',
        text: ''
      },
      categories: [
        { active: false },
        { active: false },
        { active: false },
      ],
      settingsOption: this.$route.query.option ? this.$route.query.option : 'style',
      dRoleModal: false,
      activeData: '',
      styleModal: {
        save: false,
        load: false
      },
      selectedWs: null,
      activeProject: null
    }
  },
  computed: {
    ...mapGetters({
      activeWorkspaceObj: "workspace/activeWorkspaceObj",
      projects: "workspace/workspaceSettingsInfo",
      workspaces: 'workspace/workspaces',
      notifications: 'notification/notifications',
      project: 'project/project',
    }),
    unreadNotification() {
      let unread = this.notifications.filter(item => !item.is_read);
      return unread && unread.length > 0;
    }
  },
  methods: {
    showWorkspaceCreateModal() {
      this.$store.dispatch("workspace/toggleWorkspaceModal", true);
    },
    goBack() {
      this.$router.push({name: 'workspace_dashboard'});
    },
    outsideSearchInput() {
      this.searchField.placeholder = 'Search';
    },
    toggleCategory(index) {
      this.categories[index].active = !this.categories[index].active;
    },
    toggleModalMode(value) {
      this.dRoleModal = value;
    },
    setActiveItem(data) {
      this.activeData = data.id;
      this.activeProject = data;
    },
    getWorkspaceInfo() {
      const self = this;
      let workspaceId = self.$route.params.id;
      self.$store.dispatch("workspace/getWorkspaceSettingsInfo", workspaceId).then(response => {
        if(response) {
          if(self.$route.query.type === 'project') {
            self.categories[0].active = true;
          } else if(self.$route.query.type === 'blueprint') {
            self.categories[1].active = true;
          } else if(self.$route.query.type === 'collection') {
            self.categories[2].active = true;
          }

          if(self.$route.query.id) {
            self.activeData = self.$route.query.id;
            this.$store.dispatch('project/getSolidObject', self.$route.query.id).then(response => {
              if(response && response.data && response.data.data) {
                this.activeProject = response.data.data;
              }
            });
          } else {
            this.categories[0].active = true;
            if(self.projects.length > 0) {
              self.activeData = self.projects[0].id;
              self.activeProject = self.projects[0];
            }
          }
        }
      });
    },
    getCategoryInfo(type) {
      return this.projects.filter(item => parseInt(item.type) === parseInt(type));
    },
    toggleSaveStyleModalMode(value) {
      this.styleModal.save = value;
    },
    toggleLoadStyleModalMode(value) {
      this.styleModal.load = value;
    },
    toggleWorkspace(id, index) {
      const self = this;
      self.selectedWs = id;
      self.$store.dispatch("workspace/toggleWorkspace", index);
      self.$store.dispatch("workspace/getDirectories", id);
      self.$store.dispatch("workspace/getWorkspaceData", {
        type: self.type_selected,
        workspace_id: id
      });
    },
    rightClickHandler(e, workspace) {
      e.preventDefault();
      this.showContextMenu = true;
      this.contextPos.left = e.pageX || e.clientX;
      this.contextPos.top = e.pageY || e.clientY;
      this.contextWorkspace = workspace;
    },
    hideContextMenu() {
      this.showContextMenu = false;
      this.contextWorkspace = null;
    },
    editWorkspace() {
      this.eModal = true;
      this.showContextMenu = false;
      this.$refs.workspace_edit.setEditableData(this.contextWorkspace);
    },
    deleteWorkspace() {
      this.$Progress.start();
      this.$store.dispatch("workspace/deleteWorkspace", {
        id: this.contextWorkspace.id
      }).then(response => {
        this.$Progress.finish();
      })
    },
    toggleEditModal() {
      this.eModal = false;
      this.contextWorkspace = null;
    },
  },
  watch: {
    '$route.query.id': {
      handler: function(id) {
        if(id) {
          this.categories[0].active = true;
          this.activeData = id;
          this.activeProject = this.projects.find(data => data.id == id);
        } else {
          // this.categories[0].active = true;
          // if(this.projects.length > 0 || !this.$route.query.id) {
          //   this.activeData = this.projects[0].id;
          //   this.activeProject = this.projects[0];
          // }
        }
      },
      deep: true,
      immediate: true
    },
  },
  mounted() {
    this.getWorkspaceInfo();
  }
}
</script>

<style scoped>
  .ws-action-box-mt {
    margin-top: -4px;
  }
  .ws-settings-margin{
    margin-right: 18px;
  }
  /* @media all and (max-width: 1850px) {
    .ws-settings-margin{
      height: auto;
    }
    .buttons_wrapper{
      gap: 16px;
    }
    .buttons_wrapper .buttons{
      gap: 16px;
    }
  } */
</style>
