<template>
  <div class="relative">
    <div class="ws-style-module bg-primary-three shadow-one rounded relative" v-for="(module, index) in getData"
         :key="index" :class="{ 'i-mt-30': index !== 0, 'ws-style-minimize cursor-pointer': !localModules[index].expand }">
      <div class="ws-p-show-mode" v-if="localModules[index].expand" >
        <div class="flex items-center pb-5 i-border-b-1 border-secondary-two" @click.self="toggleModuleExpand(index)">
          <div class="ws-module-c1 mr-2">
            <h3 class="text-secondary-one fs-14 font-poppins">Module:</h3>
          </div>
          <div class="mr-4">
            <input type="text" class="text-primary-one fs-14 font-poppins fw-600 bg-transparent flex"
                   :disabled="!canEdit(project?.cans)"
                   placeholder="Identification" v-model="module.title" @keyup="updateModule(module)" />
          </div>
          <div class="ws-module-c3 mr-4">
            <h3 class="text-secondary-one fs-14 font-poppins fw-600">Colour</h3>
          </div>
          <div class="ws-module-c4 flex items-center mr-4">
            <h3 class="text-secondary-one fs-14 font-poppins fw-600 mr-4">Index ID</h3>
            <label class="ws-settings-switch">
              <input type="checkbox" v-model="module.collection_settings.index_id" @change="updateSettings(index)"
                     :disabled="!canEdit(project?.cans)"
                     :checked="module.collection_settings.index_id">
              <span class="ws-settings-slider round"></span>
            </label>
          </div>
          <div class="ws-module-c5 flex items-center mr-4">
            <h3 class="text-secondary-one fs-14 font-poppins fw-600 mr-4">Index Number</h3>
            <label class="ws-settings-switch">
              <input type="checkbox" v-model="module.collection_settings.index_number" @change="updateSettings(index)"
                     :disabled="!canEdit(project?.cans)"
                     :checked="module.collection_settings.index_number">
              <span class="ws-settings-slider round"></span>
            </label>
          </div>
          <div class="ws-module-c6">
            <h3 class="text-secondary-one fs-14 font-poppins fw-600">Preview</h3>
          </div>
          <VisibilityOffIcon
              @click.native="togglePreview(module)"
              class="ml-auto w-5 h-5 cursor-pointer"
              :class="[module.visibility ? 'opacity-90' : 'opacity-20', !canEdit(project?.cans) ? 'pointer-events-none' : '']"
          />
          <MinusIcon @click.native="toggleModuleExpand(index)" class="ml-3 w-5 h-5 cursor-pointer opacity-60 hover:opacity-90" v-if="localModules[index].expand"/>
        </div>
        <div v-for="(moduleCollection, mCIndex) in module.collection_settings.collections" :key="mCIndex"
             class="i-mt-25">
          <div class="flex items-center">
            <div class="ws-module-c1 mr-2">
              <h3 class="text-secondary-one fs-14 font-poppins">{{ mCIndex === 0 ? 'Identity' : 'Level ' + mCIndex }}:
              </h3>
            </div>
            <div class="ws-module-c2 mr-4">
              <input type="text" class="text-primary-one fs-14 font-poppins bg-transparent flex"
                     placeholder="Identification" v-if="mCIndex === 0" v-model="module.title"
                     :disabled="!canEdit(project?.cans)"
                     @keyup="updateModule(module)" />
              <input type="text" class="text-primary-one fs-14 font-poppins bg-transparent flex"
                     placeholder="Identification" v-if="mCIndex > 0" v-model="moduleCollection.title"
                     :disabled="!canEdit(project?.cans)"
                     @keyup="updateSettings(index)" />
            </div>
            <div class="ws-module-c3 mr-4 flex items-center">
              <div class="flex items-center w-3/4">
                <div v-for="(color, cIndex) in getLevelWiseColorCode(mCIndex)" :key="cIndex"
                     @click="moduleCollection.color = cIndex, moduleCollection.custom_bg = '', updateSettings(index)"
                     :class="{'pointer-events-none': !canEdit(project?.cans)}"
                     v-if="mCIndex > 0">
                  <div class="ws-color-bg cursor-pointer flex justify-center bg-primary-three shadow-two i-mt-2"
                       :class="{ 'i-ml-10': cIndex !== 0 }" v-if="getSelectedColor(mCIndex, moduleCollection, cIndex)">
                    <div class="ws-circle-box" :style="{ 'background': color }"></div>
                  </div>
                  <div class="ws-circle-box cursor-pointer" :style="{ 'background': color }"
                       :class="{ 'i-ml-10': cIndex !== 0 }" v-else></div>
                </div>
              </div>

              <div class="w-1/4 relative">
                <div
                    class="ws-color-bg cursor-pointer flex justify-center items-center bg-primary-three shadow-two i-mt-2 i-ml-10">
                  <img src="/images/icons/workspace/w_color_picker.svg" alt="icon"
                       :class="{'pointer-events-none': !canEdit(project?.cans)}"
                       @click.prevent="openColorPicker(moduleCollection, mCIndex, index)" />
                </div>
              </div>
            </div>
            <div class="ws-module-c4 mr-4">
              <input type="text" class="text-secondary-one fs-14 font-poppins mr-4 bg-transparent" placeholder="ID"
                     v-model="moduleCollection.index_id" :disabled="!module.collection_settings.index_id || !canEdit(project?.cans)"
                     @keyup="updateSettings(index)" />
            </div>
            <div class="ws-module-c5 mr-4">
              <input type="text" class="text-secondary-one fs-14 font-poppins mr-4 bg-transparent w-full" placeholder="1001"
                     v-model="moduleCollection.index_number"
                     @keyup="updateIndexNumber(index, mCIndex, moduleCollection.index_number)"
                     :disabled="!module.collection_settings.index_number || mCIndex > 0 || !canEdit(project?.cans)" />
            </div>
            <div class="ws-module-c6"></div>
          </div>
        </div>
        <div style="top: 80px; right: 105px" class="absolute">
          <div class="flex ws-preview-b">
            <div class="flex flex-col gap-4">
              <div v-for="(item, iIndex) in module.collection_settings.collections" :key="iIndex"
                   class="ws-preview-h fs-12 flex items-center px-2 text-primary-three font-poppins fw-300 rounded shadow-two"
                   :style="{ 'background': getPreviewColor(module, iIndex, item) }">
                <span class="w-full truncate" v-if="iIndex !== 0"><span v-if="module.collection_settings.index_id">{{item.index_id}}</span><span v-if="module.collection_settings.index_number">{{item.index_number}}</span> {{ item.title }}</span>
                <span class="w-full truncate" v-else><span v-if="module.collection_settings.index_id">{{item.index_id}}</span><span v-if="module.collection_settings.index_number">{{item.index_number}}</span> {{ module.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ws-p-hide-mode" v-else @click="toggleModuleExpand(index)">
        <div class="flex items-center">
          <div class="ws-module-c1 mr-2">
            <h3 class="text-secondary-one fs-14 font-poppins">Module:</h3>
          </div>
          <div class="mr-4" :class="{'ws-module-c2': localModules[index].expand}">
            <h3 class="text-primary-one fs-14 font-poppins fw-600">{{ module.title }}</h3>
          </div>

          <div class="absolute module-visibility-icon">
            <div @click.stop="togglePreview(module)" :class="{'pointer-events-none': !canEdit(project?.cans)}">
              <VisibilityOffIcon
                  class=" w-5 h-5 cursor-pointer"
                  :class="[module.visibility ? 'opacity-90' : 'opacity-20']"
              />
          </div>
          </div>
        </div>
      </div>

      <div :style="{ top: pickerPositionOffset + 'px' }" class="absolute color-picker-div l-80"
           v-if="showPicker && moduleIndex === index" v-click-outside="hidePicker">
        <div class="flex flex-col items-center justify-center mt-3">
          <color-panel v-model="color" @change="onColorChange"></color-panel>
        </div>
      </div>
    </div>

    <save-style :dModal="styleModal.save" :modules="getData" :activeDataId="activeDataId"
                @toggleModalMode="toggleSaveStyleModalMode" />
    <!-- <load-style :dModal="styleModal.load" :modules="getData" :activeDataId="activeDataId"
                @toggleModalMode="toggleLoadStyleModalMode" @loadStyle="loadStyle" /> -->
  </div>
</template>

<script setup>
import usePermission from '@/composable/usePermission.js'
const { canDelete, canCreate, canEdit } = usePermission()
</script>
<script>
import { mapGetters } from 'vuex';
import SaveStyle from "./SaveStyle";
import LoadStyle from "./LoadStyle";

export default {
  name: "ProjectStyleTable",
  components: { LoadStyle, SaveStyle },
  props: ['activeDataId', 'styleModal', 'project'],
  data() {
    return {
      showPicker: false,
      colorPickerIndex: null,
      moduleIndex: null,
      color: '',
      dColors: ['#2B3943', '#38355A', '#55365A', '#2B4335', '#6A3636'],
      localModules: [],
      current: {},
      levels: ['root', 'l1', 'l2', 'l3', 'l4'],
      modulesRootName: ['Discovery', 'Strategy', 'Realization']
    }
  },
  computed: {
    ...mapGetters({
      projects: "workspace/workspaceSettingsInfo",
      colorCodes: "global/colorCodes",
    }),
    pickerPositionOffset() {
      return 100 + (30 * this.colorPickerIndex)
    },
    getData() {
      const self = this;
      let item = self.projects.find(item => parseInt(item.id) === parseInt(self.activeDataId));
      if (item) {
        item.modules.forEach(mData => {
          if (mData.collection_settings && mData.collection_settings.collections) {
            // do nothing
          } else {
            mData.collection_settings = JSON.parse(mData.collection_settings);
          }
        });
        this.setLocalModules(item.modules);
        return item.modules;
      }
      return [];
    },
  },
  methods: {
    setLocalModules(modules) {
      let newLength = modules.length - this.localModules.length;
      if(newLength > 0) {
        for(let i=0; i<=newLength; i++) {
          if(this.$route.query.id) {
            this.localModules.push({ expand: true });
          } else {
            this.localModules.push({ expand: false });
          }
        }
      }
    },
    togglePreview(module) {
      module.visibility = module.visibility ? 0 : 1
      this.updateModule(module)
    },
    openColorPicker(obj, index, moduleIndex) {
      const self = this;
      setTimeout(function () {
        if (self.showPicker && self.moduleIndex === moduleIndex) {
          self.showPicker = false;
          self.colorPickerIndex = null;
          self.moduleIndex = null;
        } else {
          self.colorPickerIndex = index;
          self.moduleIndex = moduleIndex;
          self.current = obj;
          self.showPicker = !self.showPicker;
        }
      }, 100);
    },
    hidePicker() {
      if (this.showPicker) {
        this.showPicker = false;
        this.colorPickerIndex = null;
        this.moduleIndex = null;
      }
    },
    toggleModuleExpand(index) {
      this.localModules[index].expand = !this.localModules[index].expand;
    },
    updateModule(module) {
      this.$store.dispatch("project/updateModule", {
        id: module.id,
        title: module.title,
        visibility: module.visibility
      });
    },
    updateIndexNumber(index, mcIndex, value) {
      let module = this.getData[index];
      if (!isNaN(value)) {
        let indexNumber = value;
        if (mcIndex === 0) {
          module.collection_settings.collections.forEach((item, iIndex) => {
            if (iIndex > 0) {
              item.index_number = indexNumber;
              let newNumber = indexNumber.toString() + '.1';
              indexNumber = newNumber;
            }
          });

          this.updateSettings(index);
        }
      } else {
        module.collection_settings.collections[0].index_number = module.collection_settings.collections[1].index_number;
      }
    },
    updateSettings(index) {
      let module = this.getData[index];
      let data = {
        id: module.id,
        collection_settings: module.collection_settings
      };
      this.$store.dispatch("project/updateModuleSettings", data);
    },
    toggleSaveStyleModalMode(value) {
      this.$emit("toggleSaveStyleModalMode", value);
    },
    toggleLoadStyleModalMode(value) {
      this.$emit("toggleLoadStyleModalMode", value);
    },
    loadStyle(data) {
      const self = this;
      self.getData.forEach((item, index) => {
        if(data[index]) {
          item.collection_settings = data[index].collection_settings;
        }
      });
    },
    getRootModule(module, index) {
      let moduleCollections = module.collection_settings.collections;
      if (moduleCollections.length > 0) {
        return moduleCollections[0].title;
      }
      return 'N/A';
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      indexLevel = indexLevel < 0 ? 0 : indexLevel
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    getSelectedColor(level, collection, loopColorIndex) {
      if (collection.custom_bg) {
        return false;
      }
      return loopColorIndex === collection.color;
    },
    getPreviewColor(module, level, collection) {
      if (collection.custom_bg) {
        return collection.custom_bg;
      }
      let colors = this.getLevelWiseColorCode(level);
      if (colors && colors.length > 0) {
        return colors[collection.color];
      }
      return '';
    },
    onColorChange(color) {
      this.current.custom_bg = color;
      this.updateSettings(this.moduleIndex);
    },
  }
}
</script>

<style scoped>
.ws-settings-switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 17px;
}

.ws-settings-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.ws-settings-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  -webkit-transition: .4s;
  transition: .4s;
  border: 1px solid #414141;
}

.ws-settings-slider:before {
  position: absolute;
  content: "";
  height: 9px;
  width: 9px;
  left: 3px;
  top: 3px;
  background-color: #414141;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.ws-settings-slider {
  background-color: transparent;
  border: 1px solid #2A93C0;
}

input:focus+.ws-settings-slider {
  box-shadow: 0 0 1px transparent;
}

input:checked+.ws-settings-slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
  left: 6px;
  background-color: #2A93C0;
}

.ws-settings-slider.round {
  border-radius: 8px;
}

.ws-settings-slider.round:before {
  border-radius: 50%;
}

.color-picker-div {
  border-radius: 0 0 2px 2px;
  width: 235px;
  z-index: 9999999;
  top: 0;
  left: 671px;
}
.module-visibility-icon {
  right: 10px;
}
</style>
