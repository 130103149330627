<template>
  <div class="flex">
    <div class="i-ml-35">
      <button :class="{'cursor-not-allowed': !activeDataId || activeDataId == null || activeDataId === undefined, 'cursor-pointer': activeDataId}"
              :content="!activeDataId ? 'Please select a project' : 'Open people invitation dialog'"
              v-tippy="{ placement : 'top',  arrow: true }"
              @click="toggleModalMode(true)" class="focus:outline-none text-primary-one fs-14 font-poppins fw-600 bg-transparent i-border-1 border-secondary-one w-full rounded flex items-center justify-center i-pt-10 i-pb-10 i-pl-35 i-pr-35">
        Add People
      </button>
    </div>
    <div class="i-ml-35">
      <router-link :to="{ name: 'definition', params: { id: activeDataId } }"
                   :event="activeDataId ? 'click' : ''"
                   :content="!activeDataId ? 'Please select a project' : 'Go to Definitions'"
                   v-tippy="{ placement : 'top',  arrow: true }"
                   :class="{'cursor-not-allowed': !activeDataId || activeDataId == null || activeDataId === undefined}"
                   class="focus:outline-none text-primary-one fs-14 font-poppins fw-600 bg-transparent i-border-1 border-secondary-one w-full rounded flex items-center justify-center i-pt-10 i-pb-10 i-pl-35 i-pr-35">
        Definitions
      </router-link>
    </div>
    <p-r-modal :dModal="dRoleModal" :existingResponsible="getDataWithOwner" :activeDataId="activeDataId" @toggleModalMode="toggleModalMode" />
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "RoleButtons",
  props: ['activeDataId'],
  data() {
    return {
      dRoleModal: false
    }
  },
  computed: {
    ...mapGetters({
      projects: "workspace/workspaceSettingsInfo"
    }),
    getDataWithOwner() {
      const self = this;
      let item = self.projects.find(item => parseInt(item.id) === parseInt(self.activeDataId));
      if(item) {
        return item.responsibles;
      }
      return [];
    }
  },
  methods: {
    toggleModalMode(value) {
      if(this.activeDataId) {
        this.dRoleModal = value;
      }
    },
  }
}
</script>

<style scoped>

</style>
