<template>
  <Modal v-model="showModal" @close="showModal=false">
    <div class="p-10">
      <div class="flex i-border-b-1 border-secondary-four">
        <h3 class="font-poppins text-primary-one i-border-b-1 border-primary-one opacity-100 fs-20 cursor-pointer ws-modal-item-selector">
          Load From Library</h3>
      </div>
      <div class="i-mt-30">
        <div class="ws-scrollbar ws-update-list overflow-y-auto">
          <div v-for="(styleItem, index) in projectStyles" :key="index"
               @click="selectedObj = styleItem"
               class="h-10 w-full fs-14 font-poppins text-secondary-one opacity-60 cursor-pointer flex items-center px-3 ws-update-item border-secondary-two flex justify-between"
               :class="{'opacity-100 i-border-1': selectedObj.id === styleItem.id}">
            <h4>{{ styleItem.title }}</h4>
            <h4 class="remove-icon" @click="deleteStyle(styleItem.id)">❌</h4>
          </div>
        </div>
        <div class="flex i-mt-30">
          <button
              class="fs-14 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one"
              :disabled="loading" @click="loadStyle()">Load
          </button>
          <button
              class="focus:outline-none fs-14 fw-600 font-poppins i-pl-35 i-pr-35 i-border-1 border-secondary-one ml-5 rounded text-primary-one h-10 w-40"
              @click="closeModal()" :disabled="loading">Cancel
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import {mapGetters} from 'vuex';
import Modal from '@/elements/atom/Modal.vue'

export default {
  components: { Modal },
  name: "LoadStyle",
  props: ['dModal', 'modules', 'activeDataId'],
  created() {
    this.$store.dispatch("project/loadStyles", this.modules.length);
  },
  data() {
    return {
      showModal: this.dModal,
      selectedObj: {}
    }
  },
  computed: {
    ...mapGetters({
      loading: "GET_LOADING_STATE",
      projectStyles: "project/projectStyles"
    }),
  },
  watch: {
    dModal: function (val) {
      if (val) {
        this.openModal();
        this.$store.dispatch("project/loadStyles", this.modules.length);
      } else {
        this.closeModal();
      }
    }
  },
  methods: {
    openModal() {
      this.$store.dispatch("workspace/getWorkspaceUser", this.$route.params.id);
      this.showModal = true
    },
    closeModal() {
      this.selectedObj = {};
      this.$emit('toggleModalMode', false);
      this.showModal = false
    },
    async loadStyle() {
      const self = this;
      self.$store.state.loading = true;
      let allStyles = [];
      let moduleIds = self.modules.map(item => item.id);
      // self.modules.forEach(item => {
      //   allStyles.push({id: item.id, collection_settings: JSON.stringify(item.collection_settings)});
      // });
      await self.$store.dispatch("project/loadSavedStyle", {
        module_ids: moduleIds,
        style_id: self.selectedObj.id,
        project_id: self.activeDataId
      })
      .then(response => {
        if (response) {
          self.$emit("loadStyle", response.data.data);
          self.closeModal();
        } else {
          alert('Something went wrong!!!');
        }
      });
      self.$store.state.loading = false;
    },
    deleteStyle(id) {
      if(confirm('Are you sure want to delete this?')) {
        this.$Progress.start();
        this.$store.dispatch("project/deleteStyle", id).then(response => {
          this.$Progress.finish();
          this.$store.dispatch("project/loadStyles", this.modules.length)
        })
      }
    }
  }
}
</script>

<style scoped>
.remove-icon {
  color: #000000;
  font-family: 'Segoe UI Symbol', EmptyFont2;
}
</style>
