<template>
  <table class="w-full i-n-mt-35">
    <tr class="ws-role-table-tr bg-primary-three rounded" v-for="(responsible, index) in getData" :key="index">
      <td class="text-left">
        <div class="flex items-center i-ml-30">
          <img src="/images/icons/workspace/avatar_1.svg" alt="avatar" class="table-avatar">
          <h2 class="fs-18 font-poppins i-ml-30 fw-600 text-primary-one">{{ responsible.user_id ? getUserName(responsible.user) : 'N/A' }}</h2>
        </div>
      </td>
      <td class="font-poppins text-secondary-one fs-16 text-left">{{ responsible.user_id ? responsible.user.email : responsible.email }}</td>
      <td class="font-poppins text-secondary-one fs-16 text-left">
        <select class="fs-16 ws-select-dropdown bg-transparent font-poppins text-secondary-one" v-model="responsible.role" @change="updateRole(responsible.id, responsible.role)">
          <option class="text-left font-poppins fs-16 text-secondary-one" :value="1">Admin</option>
          <option class="text-left font-poppins fs-16 text-secondary-one" :value="2">Editor</option>
          <option class="text-left font-poppins fs-16 text-secondary-one" :value="4">Viewer</option>
        </select>
      </td>
      <td class="font-poppins text-secondary-one fs-16 text-left">
        <select class="fs-16 ws-select-dropdown bg-transparent font-poppins text-secondary-one">
          <option class="text-left font-poppins fs-16 text-secondary-one">{{ roleActions[responsible.role - 1] }}</option>
        </select>
      </td>
      <td class="font-poppins text-secondary-one fs-16 text-left">{{ responsible.created_at }}</td>
      <td class="font-poppins text-secondary-one fs-16 text-left">
        <div class="flex items-center">
          <div class="cursor-pointer flex justify-center items-center hover:bg-secondary-three py-1" @click="deleteProjectResponsible(responsible.id)">
            <img src="/images/icons/trash.svg" alt="delete" class="ws-table-icon">
          </div>
          <div class="cursor-pointer flex justify-center items-center hover:bg-secondary-three ml-10 py-1">
            <img src="/images/icons/more_vertical_dot.svg" alt="more" class="ws-table-icon">
          </div>
        </div>
      </td>
    </tr>
    <p-r-modal :dModal="dRoleModal" :existingResponsible="getDataWithOwner" :activeDataId="activeDataId" @toggleModalMode="toggleModalMode" />
  </table>
</template>

<script>
  import { mapGetters } from 'vuex';
export default {
  name: "ProjectRoleTable",
  props: ['dRoleModal', 'activeDataId'],
  data() {
    return {
      roleActions: ['Can edit', 'Can edit', 'Can view', 'Can view']
    }
  },
  computed: {
    ...mapGetters({
      projects: "workspace/workspaceSettingsInfo"
    }),
    getData() {
      const self = this;
      let item = self.projects.find(item => parseInt(item.id) === parseInt(self.activeDataId));
      if(item) {
        return item.responsibles.filter(responsible => parseInt(responsible.user_id) !== parseInt(item.owner_id));
      }
      return [];
    },
    getDataWithOwner() {
      const self = this;
      let item = self.projects.find(item => parseInt(item.id) === parseInt(self.activeDataId));
      if(item) {
        return item.responsibles;
      }
      return [];
    }
  },
  methods: {
    toggleModalMode(value) {
      this.$emit("toggleModalMode", value);
    },
    getUserName(user) {
      let title = '';
      if(user.first_name) {
        title = user.first_name;
        if(user.last_name) {
          title = title + ' ' + user.last_name;
        }
      } else {
        title = '';
      }
      return title;
    },
    updateRole(id, role) {
      let obj = {
        id: id,
        role: role
      };
      this.$store.dispatch('workspace/updateRole', obj);
    },
    deleteProjectResponsible(id) {
      const self = this;
      let workspaceId = self.$route.params.id;
      if(confirm('Do you want to delete this?')) {
        self.$store.dispatch("workspace/deleteProjectResponsible", id).then(response => {
          if(response) {
            self.$store.dispatch("workspace/getWorkspaceSettingsInfo", workspaceId)
          }
        });
      }
    }
  }
}
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0 36px;
}
td {
  border-style: none solid solid none;
}
tr td:first-child {
  border-top-left-radius: 4px;
}
tr td:last-child {
  border-top-right-radius: 4px;
}
tr td:first-child {
  border-bottom-left-radius: 4px;
}
tr td:last-child {
  border-bottom-right-radius: 4px;
}
tr td {
  border-top-style: solid;
}
tr td:first-child {
  border-left-style: solid;
}
</style>
