<template>
  <Modal v-model="showModal">
    <div class="p-10">
      <div class="flex i-border-b-1 border-secondary-four">
        <h3 class="font-poppins text-primary-one opacity-50 fs-20 cursor-pointer"
            :class="{'ws-modal-item-selector i-border-b-1 border-primary-one opacity-100': type_selected === 'new'}"
            @click="type_selected = 'new'">Save as new</h3>
        <h3 class="font-poppins text-primary-one opacity-50 fs-20 ws-modal-mr cursor-pointer"
            :class="{'ws-modal-item-selector i-border-b-1 border-primary-one opacity-100': type_selected === 'update'}"
            @click="type_selected = 'update'">Update</h3>
      </div>
      <div class="i-mt-30">
        <div class="" v-if="type_selected === 'new'">
          <h4 class="font-poppins text-secondary-one fs-14">Title</h4>
          <div class="flex justify-between items-center mt-2 relative">
            <div class="modal-collaborator flex-3 pr-3 relative">
              <input type="text"
                    class="bg-transparent font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-primary-one rounded"
                    :placeholder="placeholder.title" @click="placeholder.title = ''"
                    v-click-outside="setTitlePlaceholder"
                    v-model="styleObj.title">
            </div>
          </div>
        </div>
        <div class="i-mt-30" v-if="type_selected === 'new'">
          <h4 class="font-poppins text-secondary-one fs-14">Description (Optional)</h4>
          <div class="flex justify-between items-center mt-2 relative">
            <div class="flex-3 pr-3 relative">
                            <textarea
                                class="bg-transparent font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-primary-one rounded py-2"
                                :placeholder="placeholder.description" @click="placeholder.description = ''"
                                v-click-outside="setDescriptionPlaceholder" v-model="styleObj.description" rows="4"
                                style="height: auto"/>
            </div>
          </div>
        </div>
        <div v-if="type_selected === 'update'">
          <div class="relative" v-if="!showList">
            <h4 class="font-poppins text-secondary-one fs-14">Title</h4>
            <div class="flex justify-between items-center mt-2 relative">
              <div class="modal-collaborator flex-3 pr-3 relative">
                <input type="text"
                      class="bg-transparent font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-primary-one rounded"
                      :placeholder="placeholder.title" @click="placeholder.title = ''"
                      v-click-outside="setTitlePlaceholder"
                      v-model="selectedObj.title">
              </div>
            </div>
            <h2 class="font-poppins fs-14 absolute open-list-btn cursor-pointer text-primary-one" @click="showList = true">Open List</h2>
          </div>
          <div class="i-mt-30" v-if="!showList">
            <h4 class="font-poppins text-secondary-one fs-14">Description (Optional)</h4>
            <div class="flex justify-between items-center mt-2 relative">
              <div class="flex-3 pr-3 relative">
                <textarea
                    class="bg-transparent font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-primary-one rounded py-2"
                    :placeholder="placeholder.description" @click="placeholder.description = ''"
                    v-click-outside="setDescriptionPlaceholder" v-model="selectedObj.description" rows="4"
                    style="height: auto"/>
              </div>
            </div>
          </div>
          <div v-if="showList" class="ws-scrollbar ws-update-list overflow-y-auto">
            <div v-for="(styleItem, index) in projectStyles" :key="index"
              @click="selectedObj = styleItem, showList = false"
              class="h-10 w-full fs-14 font-poppins text-secondary-one opacity-60 cursor-pointer flex items-center px-3 ws-update-item border-secondary-two"
              :class="{'opacity-100 i-border-1': selectedObj.id === styleItem.id}"
            >
              {{ styleItem.title }}
            </div>
          </div>
        </div>
        <div class="flex i-mt-30">
          <button class="fs-14 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one"
                  :disabled="loading" v-if="type_selected === 'new'" @click="saveStyle()">Save
          </button>
          <button class="fs-14 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one"
                  :disabled="loading" v-if="type_selected === 'update'" @click="updateStyle()">Update
          </button>
          <button
              class="focus:outline-none fs-14 fw-600 font-poppins i-pl-35 i-pr-35 i-border-1 border-secondary-one ml-5 rounded text-primary-one h-10 w-40"
              @click="closeModal()" :disabled="loading">Cancel
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/elements/atom/Modal.vue'
import { mapGetters } from 'vuex';

export default {
  name: "SaveStyle",
  components: { Modal },
  props: ['dModal', 'modules', 'activeDataId'],
  created() {
    this.$store.dispatch("project/loadStyles", this.modules.length);
  },
  data() {
    return {
      showModal: false,
      placeholder: {
        title: 'Type here',
        description: 'Type here'
      },
      styleObj: {
        title: '',
        description: ''
      },
      type_selected: 'new',
      showList: true,
      selectedObj: {}
    }
  },
  computed: {
    ...mapGetters({
      loading: "GET_LOADING_STATE",
      projectStyles: "project/projectStyles"
    }),
  },
  watch: {
    dModal: function (val) {
      if (val) {
        this.openModal();
        this.$store.dispatch("project/loadStyles", this.modules.length);
      } else {
        this.closeModal();
      }
    }
  },
  methods: {
    openModal() {
      this.$store.dispatch("workspace/getWorkspaceUser", this.$route.params.id);
      this.showModal = true
    },
    closeModal() {
      this.placeholder = {
        title: 'Type here',
        description: 'Type here'
      };
      this.styleObj = {
        title: '',
        description: ''
      };
      this.selectedObj = {};
      this.type_selected = 'new';
      this.showList = true;
      this.$emit('toggleModalMode', false);
      this.showModal = false
    },
    setTitlePlaceholder() {
      this.placeholder.title = 'Type here';
    },
    setDescriptionPlaceholder() {
      this.placeholder.description = 'Type here';
    },
    saveStyle() {
      const self = this;
      self.$store.state.loading = true;
      let data = self.styleObj;
      let allStyles = [];
      self.modules.forEach(item => {
        allStyles.push(item.collection_settings);
      });
      data.project_id = self.activeDataId;
      data.total_module = self.modules.length;
      data.collection_settings = JSON.stringify(allStyles);
      self.$store.dispatch("project/saveNewProjectStyle", data)
          .then(response => {
            if (response) {
              self.closeModal();
            } else {
              alert('Something went wrong!!!');
            }
            self.$store.state.loading = false;
          });
    },
    updateStyle() {
      const self = this;
      self.$store.state.loading = true;
      let data = {
        id: self.selectedObj.id,
        title: self.selectedObj.title,
        description: self.selectedObj.description,
      };
      let allStyles = [];
      self.modules.forEach(item => {
        allStyles.push(item.collection_settings);
      });
      data.collection_settings = JSON.stringify(allStyles);
      self.$store.dispatch("project/saveToAStyle", data)
          .then(response => {
            if (response) {
              self.closeModal();
            } else {
              alert('Something went wrong!!!');
            }
            self.$store.state.loading = false;
          });
    }
  }
}
</script>

<style scoped>

</style>